import React from "react";

import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing";
import Seo from "../components/Seo";
import config from "../../data/SiteConfig";
import { StaticImage } from "gatsby-plugin-image";

import { styled } from "@mui/material/styles";

const IntroductionTextBox = styled("span")(`
  position: absolute;
  right: 20px;
  left: 20px;
  top: 5%;
  width: 40%;
  min-width: 300px;
  height: 90%;
  padding: 8px;
  padding-left: 12px;
  border-radius: 5px;
  background: rgba(240, 255, 255, 0.6);
  @media (max-width: 960px) {
    width: '75%'
  }
`);

const Index = ({ data }) => {
  return (
    <Layout>
      <main>
        <Helmet title={config.siteTitle} />
        <Seo />

        <div
          style={{
            position: "relative",
            marginTop: "-10px",
            marginBottom: "20px",
            minHeight: "250px",
            minWidth: "340px",
          }}
        >
          <StaticImage
            src="../../content/blog/2021-01-14-scaling-from-seed-packet-to-diet/august_garden.jpg"
            alt="Introduction background image - our garden in August"
            style={{
              borderRadius: "5px",
              minHeight: "320px",
            }}
          />
          <IntroductionTextBox>
            Welcome to the Orcas Island Seed Bank!
            <br />
            <br />
            Here you'll find information about what we're up to, our current
            seed offerings, and our thoughts on how to contribute to a thriving,
            local, community-driven food system.
          </IntroductionTextBox>
        </div>

        <PostListing postEdges={data.allMarkdownRemark.edges} />
      </main>
    </Layout>
  );
};

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  transformOptions: { fit: COVER }
                )
              }
            }
            date
            categories
          }
        }
      }
    }
  }
`;
